import request from "@/libs/utils/request";

export const postFbmessengerPage=(group_id:string,{app_id,access_token}):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `admin_api/groups/${group_id}/fbmessenger_pages`,
        method: 'post',
        data:{app_id,access_token}
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const getFbmessengerPages=(group_id:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `admin_api/groups/${group_id}/fbmessenger_pages`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});